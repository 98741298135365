import React, { useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";
import combinedServices from "../../shared/services/user-service";
import { NavLink, useLocation } from "react-router-dom";
import userConstants from "../../shared/constants/user-constants";
import "./MyFeed.scss";
import logo from "../LiveFeed/logo.png";
import { useMemo } from "react";
import { Spinner } from "reactstrap";
import getLocalData from "../../shared/localStorage/getData";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import swal from "sweetalert";

export default function MyFeed() {
  const [feeds, setFeeds] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [rounds, setRounds] = useState(0);
  const [scoreData, setScoreData] = useState([]);
  const [heatSheetGenerated, setHeatSheetGenerated] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [participantId, setParticipantId] = useState("");
  const eventId = useLocation().pathname.split("/")[3];
  const socket = useMemo(() => {
    return io.connect(userConstants.baseURL);
  }, []);

  useEffect(async () => {
    let userId, scoreArrayData;
    if (getLocalData("user_info", "isLogin")) {
      userId = getLocalData("user_info", "all").userData.login_user_detail.data
        .user.id;
    }
    setParticipantId(userId);
    let data = {
      eventId: eventId,
      userId: userId,
    };
    let scoreResponse = await combinedServices.getScoreForFeed(data);
    let eventResponse = await combinedServices.getEventDetail(eventId);
    let upcomingHeats = await combinedServices.getFeed({
      eventId: eventId,
      upcomingHeats: true,
    });
    setFeeds(upcomingHeats.data.data);
    setEventTitle(eventResponse.data.event_full_title);
    setRounds(eventResponse.data.event_rounds_per_class);
    setScoreData(scoreResponse.data.data);
    scoreArrayData = scoreResponse.data.data;
    if (scoreResponse.data.data.length > 0) {
      setHeatSheetGenerated(true);
    }
    setIsLoader(false);

    const handleNewScore = (newScore) => {
      if (newScore[0].event_Id == eventId) {
        if (newScore[0].event_Id == eventId) {
          // Create a copy of the previous score data
          const previousScoreData = [...scoreArrayData];

          // Find the index of the class in the previous score data
          const classIndex = previousScoreData.findIndex(
            (obj) => obj.class_id === newScore[0].class_Id
          );

          if (classIndex !== -1) {
            // Find the index of the participant within the class
            const participantIndex = previousScoreData[classIndex][
              "participants"
            ].findIndex(
              (obj) => obj.participantId === newScore[0].participant_Id
            );

            if (participantIndex !== -1) {
              // Update the participant's scores
              for (let i = 1; i <= 7; i++) {
                previousScoreData[classIndex]["participants"][participantIndex][
                  `round${i}`
                ]["Score"] = newScore[0][`round${i}_Score`];
              }
              previousScoreData[classIndex]["participants"][
                participantIndex
              ].totalScore = newScore[0].total;
            } else {
              console.log("Participant not found");
            }
          } else {
            console.log("Class not found");
          }

          // Update the state with the modified score data
          setScoreData(previousScoreData);
        }
      }
    };

    const handleScratched = (scratchedHeatIds) => {
      console.log("scratchedIDs", scratchedHeatIds);
      // const oldFeeds = [...globalFeedArray]
      const previousScoreData = [...scoreArrayData];
      for (let heatId of scratchedHeatIds) {
        // change SCR status in left and right column
        // for(let feed of oldFeeds){
        //   let feedText = JSON.parse(feed.new_feed_text)
        //   let findIndex = feedText.participants.findIndex((participant)=>participant.heatId==heatId.id)
        //   if(findIndex!==-1){
        //     feedText.participants[findIndex]["scratch"]=heatId.scratch
        //     feed.new_feed_text=JSON.stringify(feedText)
        //   }
        // }

        //change SCR status in middle column

        for (let eventClass of previousScoreData) {
          for (let participant of eventClass.participants) {
            const foundElement = Object.keys(participant).find(
              (key) => participant[key]["heatId"] == heatId.id
            );
            if (foundElement) {
              participant[foundElement]["scratched"] = heatId.scratch;
            }
          }
        }
      }
      setScoreData(previousScoreData);
      // setFeeds(oldFeeds)
      // setScratched((scratched) => !scratched);
    };

    const handleCallHeat = (upcomingHeats) => {
      setFeeds(upcomingHeats.data);
    };

    socket.on("newScore", handleNewScore);
    socket.on("scratched", handleScratched);
    socket.on("upcomingHeats", handleCallHeat);
    return () => {
      // Cleanup event listeners
      socket.off("upcomingHeats", handleCallHeat);
      socket.off("newScore", handleNewScore);
      socket.off("scratched", handleScratched);
    };
  }, []);

  const settings2 = {
    fade: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
  };

  const currentTime = useCallback(() => {
    //this function will give the current local time
    // Create a new Date object
    const now = new Date();

    // Get hours and minutes from the Date object
    let hours = now.getHours();
    let minutes = now.getMinutes();

    // Determine if it's AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour format to 12-hour format
    hours = hours % 12 || 12;

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Combine hours, minutes, and AM/PM into a string
    const currentTime = hours + ":" + minutes + " " + ampm;
    return currentTime;
  }, []);

  const generateRounds = useMemo(() => {
    let arr = [];
    for (let r = 1; r <= rounds; r++) {
      arr.push(<th>{`R${r}`}</th>);
    }
    return arr;
  }, [rounds]);

  const findParticipant = (participants, id) => {
    const foundParticipant = participants.find((participant) => {
      return (
        participant.event_participant_class.event_participant.user.id == id
      );
    });
    return foundParticipant;
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  // const handleScratchParticipant = async (e, heatId, index) => {
  //   await combinedServices
  //     .updateScoreStatus([{ id: heatId, scratch: e.target.checked }])
  //     .then(async (data) => {
  //       if (data.res.data.scratch == true) {
  //         swal("You Have Been Scratched");
  //       } else {
  //         swal("You Have Been Unscratched");
  //       }
  // let upcomingHeats = await combinedServices.getFeed({
  //   eventId: eventId,
  //   upcomingHeats: true,
  // });
  // setFeeds(upcomingHeats.data.data);
  //     })
  //     .catch((err) => {
  //       swal("You could not be scratched");
  //     });
  // };

  const handleScratchParticipant = async (e, heatId, index) => {
    if (e.target.checked) {
      swal({
        title: "Scratch participant from?",
        text: "",
        icon: "warning",
        width: "12rem",
        buttons: {
          current: {
            text: "Current heat only!",
            value: "current",
          },
          all: {
            text: "All heats!",
            value: "all",
          },
          cancel: "Cancel",
        },
        content: {
          element: "div",
          attributes: {
            id: "heatOptions",
          },
        },
      }).then(async (value) => {
        switch (value) {
          case "current":
            console.log("in current");
            const res1 = await combinedServices.updateScoreStatus([
              { id: heatId, scratch: true },
            ]);
            if (res1.res.status == 200) {
              swal("You Have Been Scratched");
              let upcomingHeats = await combinedServices.getFeed({
                eventId: eventId,
                upcomingHeats: true,
              });
              setFeeds(upcomingHeats.data.data);
            }
            break;
          case "all":
            console.log("in all");
            const res2 = await combinedServices.scratchUserHeats({
              userId: participantId,
              eventId: eventId,
              scratch: true,
            });
            if (res2.status == 200) {
              swal("You Have Been Scratched From All Heats");
              let upcomingHeats = await combinedServices.getFeed({
                eventId: eventId,
                upcomingHeats: true,
              });
              setFeeds(upcomingHeats.data.data);
            } else {
              swal(res2.msg);
            }
            break;
        }
      });
    } else {
      swal("Allow participant to?", "", "warning", {
        buttons: {
          current: {
            text: "Current heat only!",
            value: "current",
          },
          all: {
            text: "All heats!",
            value: "all",
          },

          cancel: "Cancel",
        },
        content: {
          element: "div",
          attributes: {
            id: "heatOptions",
          },
        },
      }).then(async (value) => {
        switch (value) {
          case "current":
            const res1 = await combinedServices.updateScoreStatus([
              { id: heatId, scratch: false },
            ]);
            if (res1.res.status == 200) {
              swal("You Have Been Uncratched");
              let upcomingHeats = await combinedServices.getFeed({
                eventId: eventId,
                upcomingHeats: true,
              });
              setFeeds(upcomingHeats.data.data);
            }
            break;
          case "all":
            const res2 = await combinedServices.scratchUserHeats({
              userId: participantId,
              eventId: eventId,
              scratch: false,
            });
            if (res2.status == 200) {
              swal("You Have Been Uncratched From All Heats");
              let upcomingHeats = await combinedServices.getFeed({
                eventId: eventId,
                upcomingHeats: true,
              });
              setFeeds(upcomingHeats.data.data);
            } else {
              swal(res2.msg);
            }
            break;
        }
      });
    }
  };

  return (
    <>
      <div class="containerMid myfeed-container">
        <div class="newHeader">
          <div class="newLogo">
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
            <div className="topHd"> {eventTitle}</div>
          </div>
          <div class="topRight">{currentTime()} </div>
        </div>
        <div class="midPart">
          <div class="midLeft">
            <div
              class=""
              style={{
                fontWeight: "700",
                paddingBottom: "5px",
                fontSize: "17px",
              }}
            >
              Upcoming Heats
            </div>
            {feeds.length ? (
              feeds.map((feed, index) => {
                const isParticipantFound = findParticipant(
                  feed.participants,
                  participantId
                );
                return (
                  <>
                    <div style={{fontSize:'13px',fontWeight:'500'}}>{feed.heatStatus=='On Deck'?"On Deck":feed.heatStatus=='Get Ready'?"Get Ready":feed.heatStatus=='Backup'?"Backup":""}</div>
                    <div className="mb-20" key={feed.heat_number}>
                      <div
                        className="whtBox"
                        style={{
                          border: isParticipantFound
                            ? "1px solid #00abeb"
                            : "none",
                          background:
                            feed.heatStatus == "On Deck" ? "#e7e2e2" : "white",
                          boxShadow: "0",
                        }}
                      >
                        <ul className="boxList" style={{ padding: "" }}>
                          <li className="hd" style={{ position: "relative" }}>
                            <div className="heading">
                              <span>{feed.heat_number}</span>{" "}
                            </div>
                            <div style={{ fontWeight: "500" }}>
                              {
                                feed.participants[0].event_participant_class
                                  .class.name
                              }{" "}
                            </div>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {isParticipantFound ? (
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      onChange={(e) =>
                                        handleScratchParticipant(
                                          e,
                                          isParticipantFound.id,
                                          index
                                        )
                                      }
                                      checked={isParticipantFound.scratch}
                                    />
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </div>

          {isLoader ? (
            <div
              className="text-center loadMore"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <div class="midCen">
              {heatSheetGenerated ? (
                <div direction="up" className="marqBox" scrollamount="3">
                  {scoreData.length > 0 && <div class="heading">Standings</div>}
                  <div class="tableSlider">
                    {scoreData.map((scoreObj) => {
                      return (
                        <div class="item mb-20">
                          <table class="table">
                            <tr>
                              <th style={{ width: "50%" }}>
                                {scoreObj.class_name}
                              </th>
                              <th>Total</th>
                              {generateRounds?.map((round) => round)}
                            </tr>
                            {scoreObj.participants
                              .sort((a, b) => b.totalScore - a.totalScore)
                              .map((participant) => {
                                return (
                                  <tr>
                                    <td>{participant.participantName}</td>
                                    <td> {participant.totalScore}</td>
                                    {generateRounds.map((round, index) => {
                                      return (
                                        <td>
                                          {participant[`round${index + 1}`][
                                            "scratched"
                                          ]
                                            ? "SCR"
                                            : participant[`round${index + 1}`][
                                                "Score"
                                              ]}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div
                  className="noEvent"
                  style={{
                    border: "none",
                  }}
                >
                  Scores are not available
                  <div className="marqBox"></div>
                </div>
              )}
            </div>
          )}

          {isLoader && <div className="marqBox"></div>}
        </div>
      </div>
    </>
  );
}
